import React from "react";
import { useTranslation } from "react-i18next";

const Services = () => {
  const { t } = useTranslation();
  return (
    <div style={{ backgroundColor: "white", padding: "1rem" }}>
      <li>{t("serv.hab")}</li>
      <li>{t("serv.des")}</li>
      <li>{t("serv.vista")}</li>
      <li>{t("serv.wifi")}</li>
      <li>{t("serv.resto")}</li>
      <li>{t("serv.est")}</li>
      <li>Jacuzzi</li>
      <li>{t("serv.salon")}</li>
    </div>
  );
};

export default Services;
