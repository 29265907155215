import React from "react";

const Divider = ({ text }) => {
  return (
    <div
      style={{
        backgroundColor: "#cc9944",
        height: "4rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "white",
        fontSize: "1.3rem",
      }}
    >
      {text}
    </div>
  );
};

export default Divider;
