import React from "react";
import tlogo from "../Assets/img/tlogo.jpeg";
import tw from "../Assets/img/tw.svg";
import fb from "../Assets/img/fb.svg";
import insta from "../Assets/img/insta.svg";

const Footer = () => {
  return (
    <div style={{ display: "flex" }}>
      <div style={{ backgroundColor: "white", display: "flex" }}>
        <img
          src={tlogo}
          flex={1}
          style={{ width: "20vw", margin: "auto" }}
          alt="tlogo"
        />
      </div>
      <div
        style={{
          color: "white",
          padding: "1rem",
          textAlign: "center",
          width: "100%",
          fontSize: "0.6rem",
        }}
        flex={2}
      >
        <div>
          <a
            href="https://twitter.com/AltosUshuaia"
            target="_blank"
            rel="noreferrer"
          >
            <img src={tw} style={{ width: "5vw", marginRight:"1vw" }} alt="tw" />
          </a>
          <a
            href="https://www.facebook.com/altosushuaia"
            target="_blank"
            rel="noreferrer"
          >
            <img src={fb} style={{ width: "5vw", marginRight:"2vw"  }} alt="fb" />
          </a>
          <a
            href="https://www.instagram.com/altosushuaia"
            target="_blank"
            rel="noreferrer"
          >
            <img src={insta} style={{ width: "5vw" }} alt="insta" />
          </a>
        </div>
        <p>
          Luis F. Martial 1441 - Ushuaia - Tierra del Fuego - Argentina
          <br />
          Tel/Fax: +54(2901) 422990 // 422936
          <br />
          <br />
          info@altosushuaia.com // www.altosushuaia.com
        </p>
      </div>
    </div>
  );
};

export default Footer;
