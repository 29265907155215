import Description from "./components/Description";
import Divider from "./components/Divider";
import Footer from "./components/Footer";
import Images from "./components/Images";
import Services from "./components/Services";
import Video from "./components/Video";
import tlogo from "../src/Assets/img/tlogo.jpeg";
import Idioma from './components/Idioma'
import { useTranslation } from "react-i18next";

function App() {
  const { t } = useTranslation();

  return (
    <div className="App">
      <header className="App-header">
        <div>

        <div
          style={{
            backgroundColor: "#fff",
            display: "flex",
            padding: "1vw",
          }}
          >
          <img
            src={tlogo}
            flex={1}
            style={{ width: "20vw", margin: "auto" }}
            alt="tlogo"
            />
        </div>
      <Idioma />

            </div>
        <Video />
        <Divider text={t("divider.nuestro")} />
        <Services />
        <Images />
        <Divider text={t("divider.decubra")} />
        <Description />
        <Footer />
      </header>
    </div>
  );
}

export default App;
