import React from "react";
import { useTranslation } from "react-i18next";

const Description = () => {
  const { t } = useTranslation();

  return (
    <div style={{ backgroundColor: "white", padding: "1rem" }}>
      <p>{t("description.text")}</p>
      <p>{t("description.wait")}</p>
    </div>
  );
};

export default Description;
