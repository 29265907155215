import React from "react";
import "../index.css";
import { useTranslation } from "react-i18next";
import video from "../Assets/video/videohotel.mp4";

const Video = () => {
  const { t } = useTranslation();

  return (
    <>
      <div
        style={{
          background: "#fff",
          display: "flex",
          textAlign: "center",
          fontSize: "0.9rem",
          padding: "1rem",
        }}
      >
        {t("description.video")}
      </div>
      <video width="100%" loop muted autoPlay playsinline controls>
        <source src={video} type="video/mp4" />
      </video>
    </>
  );
};

export default Video;
