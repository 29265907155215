import React from "react";

import arg from "../../Assets/img/arg.jpg";
import ing from "../../Assets/img/ing.jpg";
import por from "../../Assets/img/por.jpg";

export default function Idioma() {
  const changeLanguage = (code) => {
    localStorage.setItem("language", code);
    window.location.reload();
  };

  return (
    <div
      style={{
        float: "right",
        margin: "auto",
        marginRight: "3%",
        marginTop: "-7%",
        width: "100px",
        display: "flex",
        justifyContent:"space-between"
      }}
    >
      <div onClick={() => changeLanguage("es")}>
        <img style={{ width: "30px" }} src={arg} alt="Argentino" />
      </div>
      <div onClick={() => changeLanguage("en")}>
        <img style={{ width: "30px" }} src={ing} alt="Ingles" />
      </div>
      <div onClick={() => changeLanguage("po")}>
        <img style={{ width: "30px" }} src={por} alt="Brasilero" />
      </div>
    </div>
  );
}
