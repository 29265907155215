import React from "react";
import frente from "../Assets/img/frente.jpeg";
import mat from "../Assets/img/mat.jpeg";
import twin from "../Assets/img/twin.jpeg";
import resto from "../Assets/img/resto.jpeg";
import hotel from "../Assets/img/hotel.jpeg";
import { useTranslation } from "react-i18next";

const Images = () => {
  const { t } = useTranslation();

  return (
    <a
      href="https://drive.google.com/drive/u/1/folders/1VyN1AeGl2zFgQnewtKPZNKk9MzMUfXVP"
      target="_blank"
      rel="noreferrer"
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div style={{ display: "flex" }}>
          <div
            flex={2}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
            }}
          >
            <img src={frente} style={{ width: "60vw" }} alt="frente" />
            <img src={mat} style={{ width: "60vw" }} alt="mat" />
          </div>
          <div
            flex={1}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              margin: "1%",
            }}
          >
            <img src={twin} style={{ width: "40vw" }} alt="twin" />
            <img src={resto} style={{ width: "40vw" }} alt="resto" />
            <img src={hotel} style={{ width: "40vw" }} alt="hotel" />
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <p style={{ margin: 0, color: "#fff" }}>{t("images")}</p>
        </div>
      </div>
    </a>
  );
};

export default Images;
